<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Issue')} ${$t('To Edit')} | ${$t('Invoice')} #${issueData.id != null ? issueData.id : ''}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <!-- Header -->
          <b-row>
            <!-- Field: Date -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="issue-date"
                rules="required"
              >
                <b-form-group
                  :label="$t('Date')"
                  label-for="issue-date"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      id="issue-date"
                      v-model="issueData.issue_date"
                      class="form-control invoice-edit-input"
                      :config="{ dateFormat: 'Y-m-d'}"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                      :disabled="!$can('update', 'issue_date')"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Issuer - Staff ID -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="issuer"
                rules="required"
              >
                <b-form-group
                  :label="$t('Issuer')"
                  label-for="issuer"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="issueData.issuer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="staffOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="issuer"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Issued Reason - Staff ID|Staff Name -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="issued_reason"
                rules=""
              >
                <b-form-group
                  :label="$t('Issued Reason')"
                  label-for="issued_reason"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="issueData.issued_reason"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="reasonOptions"
                    :reduce="val => val.value + '|' + val.label"
                    :clearable="false"
                    input-id="issued_reason"
                  />
                  <!-- <b-form-input
                    id="issued_reason"
                    v-model="issueData.issued_reason"
                    trim
                    placeholder=""
                  /> -->

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Reference -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Reference')"
                label-for="reference"
              >
                <b-form-input
                  id="reference"
                  v-model="issueData.reference"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- Field: Total Amount -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="total-amount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Total Amount')"
                  label-for="total-amount"
                >
                  <b-form-input
                    id="total-amount"
                    v-model="issueData.total_amount"
                    class="plain"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-row class="mb-1">

            <!-- Field: Item -->
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('Item')"
                label-for="item"
              >
                <v-select
                  v-model="itemId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="itemOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="item"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Quantity -->
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('Quantity')"
                label-for="quantity"
              >
                <b-input-group>
                  <b-form-input
                    id="quantity"
                    v-model="itemQuantity"
                    type="number"
                    trim
                    placeholder=""
                    @keyup.enter="quantityEnter"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="addNewItem"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />{{ $t('Add') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>

            </b-col>
          </b-row>

          <div
            ref="form"
            class="repeater-form"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in issueData.issue_items"
              :key="index"
              ref="row"
              class="pb-2"
            >

              <!-- Item Form -->
              <!-- ? This will be in loop => So consider below markup for single item -->
              <b-col cols="12">

                <!-- Form Input Fields OR content inside bordered area  -->
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-flex border rounded">

                  <b-row class="flex-grow-1 p-1">

                    <!-- Item Name -->
                    <b-col
                      cols="12"
                      lg="5"
                    >
                      <label class="d-inline">{{ $t('Item') }}</label>
                      <p class="mb-1">
                        {{ item.item_id }} - {{ item.item_name }}
                      </p>
                    </b-col>

                    <!-- Price -->
                    <b-col
                      cols="6"
                      lg="3"
                      sm="4"
                    >
                      <label class="d-inline">{{ $t('Price') }}</label>
                      <b-form-input
                        v-model="item.price"
                        type="number"
                        class="mb-1"
                        @input="calItemAmount(item.item_id, item.price, item.quantity)"
                      />
                    </b-col>

                    <!-- Quantity -->
                    <b-col
                      cols="6"
                      lg="2"
                      sm="4"
                    >
                      <label class="d-inline">{{ $t('Quantity') }}</label>
                      <b-form-input
                        v-model="item.quantity"
                        type="number"
                        class="mb-1"
                        @input="calItemAmount(item.item_id, item.price, item.quantity)"
                      />
                    </b-col>

                    <!-- Item Amount -->
                    <b-col
                      cols="12"
                      lg="2"
                      sm="4"
                    >
                      <label class="d-inline">{{ $t('Amount') }}</label>
                      <b-form-input
                        v-model="item.amount"
                        type="text"
                        class="mb-1 plain border-light"
                        readonly
                      />
                    </b-col>
                  </b-row>

                  <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />

                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="onSubmit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="ml-auto"
              type="button"
              variant="flat-secondary"
              @click="close"
            >
              <feather-icon icon="PowerIcon" />&nbsp;
              {{ $t('Close') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import flatPickr from 'vue-flatpickr-component'
import OfflineDB from '@/libs/offline-db'
import router from '@/router/index'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import issueStoreModule from '../issueStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      itemOptions: [],
      staffOptions: [],
      reasonOptions: [],
      itemId: null,
      itemQuantity: null,
      offlineDB: new OfflineDB(),
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'issueData.issue_items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
    this.loadStaffs()
    this.loadReasons()
    this.loadItems()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    quantityEnter() {
      this.addNewItem()
      document.getElementById('item').focus()
      // this.$refs.item.$el.focus()
    },
    loadStaffs() {
      store
        .dispatch('common/fetchStaffs', { id_name: 1 })
        .then(response => {
          const { staffs } = response.data.data
          for (let i = 0; i < staffs.length; i += 1) {
            this.staffOptions.push({ label: staffs[i].name, value: staffs[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadReasons() {
      store
        .dispatch('common/fetchReasons', { id_name: 1, tag: 'Issue' })
        .then(response => {
          const { reasons } = response.data.data
          for (let i = 0; i < reasons.length; i += 1) {
            this.reasonOptions.push({ label: reasons[i].name, value: reasons[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadItems() {
      store
        .dispatch('common/fetchItems', { id_name: 1, inventory: 1 })
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          // const someFriends = await this.offlineDB.items.where({ id: 2 }).first()
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancel() {
      this.$router.push({ name: 'issue-view', params: { id: this.$router.currentRoute.params.id } })
    },
    close() {
      this.$router.push({ name: 'issue-list' })
    },
    async addNewItem() {
      if (this.itemId !== null && this.itemQuantity !== null) {
        this.$refs.form.style.overflow = 'hidden'
        const selectedItem = await this.offlineDB.items.where({ id: this.itemId }).first()

        if (this.issueData.issue_items.some(obj => obj.item_id === this.itemId)) {
          this.$swal('Warning', 'Item already exists', 'warning')
          return
        }

        this.itemFormBlankItem.item_name = selectedItem.name
        this.itemFormBlankItem.item_id = selectedItem.id
        this.itemFormBlankItem.price = Number(selectedItem.price)
        this.itemFormBlankItem.quantity = Number(this.itemQuantity)
        this.itemFormBlankItem.amount = Number(selectedItem.price) * Number(this.itemQuantity)

        this.issueData.issue_items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.itemId = null
        this.itemQuantity = null

        this.calTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      }
    },
    removeItem(index) {
      this.issueData.issue_items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.calTotalAmount()
    },
    calItemAmount(itemId, price, quantity) {
      const index = this.issueData.issue_items.findIndex((obj => obj.item_id === itemId))
      const amount = Number(price) * Number(quantity)
      this.issueData.issue_items[index].amount = amount
      this.calTotalAmount()
    },
    calTotalAmount() {
      this.issueData.total_amount = this.issueData.issue_items.reduce((a, b) => +a + +b.amount, 0)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'issue'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, issueStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const itemFormBlankItem = {
      item_name: '',
      item_id: null,
      price: 0,
      quantity: 0,
      amount: 0,
    }
    const current = new Date()
    const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
    const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
    const today = `${current.getFullYear()}-${month}-${date}`

    const issueData = ref({
      issue_date: today,
      issuer: null,
      issued_reason: null,
      total_amount: 0,
      reference: '',
      issue_items: [],
    })

    const issueId = router.currentRoute.params.id

    store.dispatch('issue/fetchOne', { id: issueId })
      .then(response => {
        const dataItems = []
        const responseItems = response.data.data.issue_items
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            item_id: responseItems[i].item_id,
            item_name: responseItems[i].item.name,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            amount: responseItems[i].amount,
          })
        }

        const responseData = {
          id: response.data.data.id,
          issue_date: response.data.data.issue_date,
          issuer: response.data.data.issuer.id,
          issued_reason: response.data.data.issued_reason,
          total_amount: response.data.data.total_amount,
          reference: response.data.data.reference,
          issue_items: dataItems,
        }
        issueData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          issueData.value = undefined
        }
      })

    const r = router
    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(issueData.value))
      store.dispatch('issue/edit', data)
        .then(response => {
          if (response.status === 200) {
            r.push({ name: 'issue-view', params: { id: r.currentRoute.params.id } })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      issueData,
      itemFormBlankItem,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
